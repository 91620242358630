import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DeleteConfirmationDialog } from 'src/components/basic/DeleteConfirmationDialog';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { MaskPhoneNumber } from 'src/components/masks/MaskPhoneNumber';
import { theme } from 'src/constants/theme';
import { deleteOwnAccount } from 'src/helpers/auth';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { UserJobType } from 'src/types/auth.type';
import { TextFieldForm } from '../components/forms/TextFieldForm';

const useStyles = createUseStyles({
  settingsContentContainer: {
    borderRadius: '10px',
    boxShadow: '0 0 8px 2px #00000015',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
});
interface Props {
  onReset: () => void;
}

const UserConfiguration: React.FC<Props> = ({ onReset }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { control } = useFormContext();
  const isDirty = useFormState({ control }).isDirty;

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const [isDeleteAccountDialogOpen, setDeleteAccountDialogOpen] =
    useState(false);
  const onConfirmDeleteUser = () => {
    deleteOwnAccount(dispatch);
  };

  return (
    <>
      {user && (
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('settings.userSettings')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.settingsContentContainer}>
            <TextFieldForm
              name="firstName"
              control={control}
              margin="dense"
              label={`${t('common.firstName')}`}
              type="text"
              fullWidth
              variant="outlined"
            />
            <TextFieldForm
              name="lastName"
              control={control}
              margin="dense"
              id="lastName"
              label={`${t('common.lastName')}`}
              type="text"
              fullWidth
              variant="outlined"
            />
            <TextField
              value={user.email}
              disabled
              fullWidth
              label={`${t('common.email')}`}
              margin="dense"
            />
            <TextFieldForm
              name="phoneNumber"
              control={control}
              margin="dense"
              id="phone"
              label={`${t('common.phone')}`}
              type="text"
              fullWidth
              variant="outlined"
              InputProps={{
                inputComponent: MaskPhoneNumber as any,
              }}
            />
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ margin: theme.spacing(1, 0, 0.5, 0) }}
              spacing={2}
            >
              <TextFieldForm
                control={control}
                name="company"
                label={`${t('common.company')}`}
                type="text"
                fullWidth
                variant="outlined"
              />
              <SelectFieldForm
                control={control}
                name="jobType"
                allowNoSelection
                fullWidth
                label={`${t('user.jobType.label')}`}
                InputLabelProps={{ shrink: true }}
                options={Object.values(UserJobType).map((jobType) => ({
                  label: t(`user.jobType.options.${jobType}`),
                  value: jobType,
                }))}
              />
            </Stack>
            <Stack
              sx={{
                backgroundColor: 'white',
                position: 'sticky',
                bottom: 0,
                zIndex: 4,
              }}
              marginTop={2}
              direction="row"
              justifyContent={isDirty ? 'space-between' : 'start'}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => setDeleteAccountDialogOpen(true)}
              >
                {t('pages.Settings.deleteOwnAccountConfirmationDialog.title')}
              </Button>
              {isDirty ? (
                <Stack direction="row" spacing={2} marginLeft="auto">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      onReset();
                    }}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {t('common.save')}
                  </Button>
                </Stack>
              ) : null}
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}
      {!!user && (
        <DeleteConfirmationDialog
          open={isDeleteAccountDialogOpen}
          onCancel={() => setDeleteAccountDialogOpen(false)}
          onConfirm={onConfirmDeleteUser}
          title={t('pages.Settings.deleteOwnAccountConfirmationDialog.title')}
          description={t(
            'pages.Settings.deleteOwnAccountConfirmationDialog.description',
          )}
          entityText={user?.email}
        />
      )}
    </>
  );
};

export default UserConfiguration;
