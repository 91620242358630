import {
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import { getRIIBaremeSourceNameLabel } from 'src/helpers/bareme/bareme';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  Bareme,
  BaremeAffectionIndemnite,
  BaremeAffectionValue,
  BaremeSource,
} from 'src/types/bareme.type';

interface Props {
  baremes: Bareme[];
}
export const BaremeAffection: React.FC<Props> = ({ baremes }) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const sortedBaremes = useMemo(
    () =>
      baremes.sort((a, b) =>
        a.source === 'Mornet' && b.source !== 'Mornet' ? -1 : 1,
      ),
    [baremes],
  );

  const onChangeTabIndex = (
    _: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setTabIndex(value);
  };

  const getAyantDroitLabel = (
    source: BaremeSource,
    value: BaremeAffectionValue,
  ) => {
    const text = t(
      `bareme.type.AffectionValues.ayantDroit.${value.ayantDroit}.${
        value.ayantDroitType ? 'withAyantDroitType' : ''
      }.source.${source}${
        value.ayantDroitType ? `.${value.ayantDroitType}` : ''
      }`,
      {
        defaultValue: '',
      },
    );
    if (text) {
      return text;
    } else {
      return t(
        `bareme.type.AffectionValues.ayantDroit.${value.ayantDroit}.${
          value.ayantDroitType
            ? `withAyantDroitType.${value.ayantDroitType}`
            : 'label'
        }`,
      );
    }
  };

  const getVictimeDecedeeLabel = (
    source: BaremeSource,
    value: BaremeAffectionValue,
  ) => {
    const text = t(
      `bareme.type.AffectionValues.victimeDecedee.${value.victimeDecedee}.${
        value.victimeType ? 'withVictimeType' : ''
      }.source.${source}${value.victimeType ? `.${value.victimeType}` : ''}`,
      {
        defaultValue: '',
      },
    );
    if (text) {
      return text;
    } else {
      return t(
        `bareme.type.AffectionValues.victimeDecedee.${value.victimeDecedee}.${
          value.victimeType ? `withVictimeType.${value.victimeType}` : 'label'
        }`,
      );
    }
  };

  const getIndmenite = (value: BaremeAffectionIndemnite) =>
    value.value
      ? fCurrency(value.value)
      : value.fromValue && value.toValue
        ? t(
            `bareme.type.AffectionValues.indemnite.${
              value.label ? 'withLabel.' : ''
            }fromValueToValue`,
            {
              fromValue: fCurrency(value.fromValue),
              toValue: fCurrency(value.toValue),
              label: value.label,
            },
          )
        : value.fromValue
          ? t(
              `bareme.type.AffectionValues.indemnite.${
                value.label ? 'withLabel.' : ''
              }fromValue`,
              {
                fromValue: fCurrency(value.fromValue),
                label: value.label,
              },
            )
          : value.toValue
            ? t(
                `bareme.type.AffectionValues.indemnite.${
                  value.label ? 'withLabel.' : ''
                }toValue`,
                {
                  toValue: fCurrency(value.toValue),
                  label: value.label,
                },
              )
            : value.label;
  const isIndemniteCoursDassiseColumnVisible = useMemo(
    () =>
      (
        sortedBaremes?.[tabIndex]?.values as BaremeAffectionValue[] | undefined
      )?.some((value) => !!value.indemniteCoursDassise),
    [sortedBaremes, tabIndex],
  );
  return (
    <Stack sx={{ maxWidth: '100%', height: '100%' }}>
      <Tabs value={tabIndex} onChange={onChangeTabIndex} variant="scrollable">
        {sortedBaremes.map((bareme, index) => (
          <Tab
            key={index}
            label={getRIIBaremeSourceNameLabel(bareme)}
            sx={{ maxWidth: 'none', textTransform: 'none' }}
          />
        ))}
      </Tabs>
      <Typography variant="body2" fontStyle="italic" sx={{ paddingY: 1 }}>
        {sortedBaremes?.[tabIndex]?.sourceComment}
      </Typography>
      {sortedBaremes.map((bareme, index) => (
        <Stack
          key={index}
          sx={{
            display: tabIndex === index ? '' : 'none',
            maxWidth: '100%',
            height: '100%',
          }}
        >
          <TableContainer
            key={index}
            sx={{
              height: '100%',
            }}
          >
            <NormalTable stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('bareme.type.AffectionValues.ayantDroit.label')}
                  </TableCell>
                  <TableCell>
                    {t('bareme.type.AffectionValues.victimeDecedee.label')}
                  </TableCell>
                  <TableCell>
                    {t('bareme.type.AffectionValues.indemnite.label')}
                  </TableCell>
                  {isIndemniteCoursDassiseColumnVisible && (
                    <TableCell align="center">
                      {t(
                        'bareme.type.AffectionValues.indemniteCoursDassise.label',
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(bareme.values as BaremeAffectionValue[]).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {getAyantDroitLabel(bareme.source, row)}
                    </TableCell>
                    <TableCell>
                      {getVictimeDecedeeLabel(bareme.source, row)}
                    </TableCell>
                    {row.indemnite && (
                      <TableCell>{getIndmenite(row.indemnite)}</TableCell>
                    )}
                    {row.indemniteCoursDassise && (
                      <TableCell align="center">
                        {getIndmenite(row.indemniteCoursDassise)}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </NormalTable>
          </TableContainer>
        </Stack>
      ))}
    </Stack>
  );
};
