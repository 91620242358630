import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { t } from 'i18next';

interface SearchFormFieldProps {
  placeholderKey: string;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  sx?: object;
}

const SearchFormField: React.FC<SearchFormFieldProps> = ({
  placeholderKey,
  searchQuery,
  setSearchQuery,
  sx = {},
}) => {
  return (
    <TextField
      placeholder={t(placeholderKey)}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      sx={{
        ...sx,
      }}
      InputProps={{
        endAdornment: searchQuery ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => setSearchQuery('')}
              sx={{ color: 'primary.main' }}
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default SearchFormField;
