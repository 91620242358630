import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  enUS,
  frFR,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'src/components/basic/Flex';
import { CustomLoadingOverlay } from 'src/components/dataGrid/CustomLoadingOverlay';
import { CustomNoRowsOverlay } from 'src/components/dataGrid/CustomNoRowsOverlay';
import { fDate } from 'src/helpers/formatTime';
import { useAppDispatch } from 'src/hooks/store';
import {
  cancelOrganizationSubscriptionAsAdmin,
  deleteOrganization,
  updateOrganizationSubscription,
} from 'src/slices/organization';
import { Organization } from 'src/types/organization.type';
import {
  Subscription,
  UpdateSubscriptionDto,
} from 'src/types/subscription.type';
import { ConfirmationDialog } from '../basic/ConfirmationDialog';
import SearchFormField from '../forms/SearchFormField';
import OrganizationCard from './OrganizationCard';

type SubscriptionType =
  | 'manual'
  | 'automatic'
  | 'free'
  | 'freeTrialAutomatic'
  | 'freeTrialManual'
  | 'none';

type GridRowType = Organization & {
  id: string;
  subscriptionType: SubscriptionType;
} & Pick<Subscription, 'maxNumberOfUsers'>;
interface Props {
  organizations: Organization[];
  selectedOrganization: Organization | null;
  setSelectedOrganization: (organization: Organization | null) => void;
}
export const AdminOrganizations: React.FC<Props> = ({
  organizations,
  selectedOrganization,
  setSelectedOrganization,
}) => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();

  const [selectedOrganizationsIds, setSelectedOrganizationsIds] = useState<
    string[]
  >([]);
  const [clickedOrganizationId, setClickedOrganizationId] = useState<
    string | null
  >(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);

  const [deleteOrganizationDialog, setDeleteOrganizationDialog] =
    useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [
    cancelSubscriptionDialogSubscriptionId,
    setCancelSubscriptionDialogSubscriptionId,
  ] = useState<{
    organizationId: string;
    subscriptionId: string;
  }>();

  const selectedOrganizations = useMemo(
    () =>
      selectedOrganizationsIds
        .map((id) =>
          organizations.find((organization) => organization._id === id),
        )
        .filter((organization): organization is Organization => !!organization),
    [selectedOrganizationsIds, organizations],
  );

  const clickedOrganization = useMemo(
    () =>
      organizations.find(
        (organization) => organization._id === clickedOrganizationId,
      ),
    [clickedOrganizationId, organizations],
  );

  useMemo(() => {
    if (selectedOrganization) {
      setDialogOpened(true);
      setClickedOrganizationId(selectedOrganization._id);
      setSearchQuery(selectedOrganization.name);
      setSelectedOrganization(null);
    }
  }, [selectedOrganization, setSelectedOrganization]);

  const filteredOrganizations = useMemo(
    () =>
      organizations.filter((organization) =>
        organization.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [searchQuery, organizations],
  );

  const getMaxColumnWidth = (field: keyof GridRowType) => {
    const maxLength = Math.max(
      ...filteredOrganizations.map((organization) => {
        return (organization as any)[field]?.toString().length || 0;
      }),
    );
    return maxLength * 13;
  };

  const columns: GridColDef<GridRowType>[] = [
    {
      field: 'name',
      headerName: t(`organization.fields.name.columnHeader`),
      minWidth: getMaxColumnWidth('name'),
    },
    {
      field: 'type',
      headerName: t('organization.fields.type.columnHeader'),
      renderCell: (cellValues) =>
        cellValues.row.type
          ? t(`organization.fields.type.options.${cellValues.row.type}`)
          : '',
      minWidth: getMaxColumnWidth('type'),
    },
    {
      field: 'subscriptionType',
      headerName: t('pages.Admin.subscription.title'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        if (cellValues.row.subscriptionType === 'none') {
          return <CloseIcon color="error" style={{ marginLeft: 8 }} />;
        } else {
          return t(
            `pages.Admin.subscription.tableColumnHeaders.${cellValues.row.subscriptionType}`,
          );
        }
      },
      minWidth: 200,
    },
    {
      field: 'totalMembers',
      headerName: t('pages.Admin.organization.totalMembers'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => (
        <Typography>{cellValues.row.members?.length}</Typography>
      ),
      minWidth: 150,
    },
    {
      field: 'maxNumberOfUsers',
      headerName: t('pages.Admin.organization.maxNumberOfUsers'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => (
        <Typography>{cellValues.row.maxNumberOfUsers ?? 0}</Typography>
      ),
      minWidth: 150,
    },
    {
      field: 'totalVictimes',
      headerName: t('pages.Admin.totalVictimes'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => (
        <Typography>{cellValues.row.victimes?.length || 0}</Typography>
      ),
    },
    {
      field: 'subscriptionPeriodStartDate',
      headerName: t(
        'pages.Admin.organization.columnHeader.subscriptionPeriodStartDate',
      ),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => (
        <Typography>
          {cellValues.row.subscription?.subscriptionPeriodEndDate
            ? fDate(cellValues.row.subscription?.subscriptionPeriodEndDate)
            : cellValues.row.subscription?.subscriptionPeriodStartDate
              ? fDate(cellValues.row.subscription?.subscriptionPeriodStartDate)
              : '-'}
        </Typography>
      ),
      minWidth: 210,
    },
    {
      field: 'subscriptionPeriodEndDate',
      headerName: t(
        'pages.Admin.organization.columnHeader.subscriptionPeriodEndDate',
      ),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => (
        <Typography>
          {cellValues.row.subscription?.subscriptionPeriodEndDate
            ? fDate(cellValues.row.subscription?.subscriptionPeriodEndDate)
            : '-'}
        </Typography>
      ),
      minWidth: 210,
    },
    {
      field: 'creationDate',
      headerName: t('pages.Admin.organization.creationDate'),
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      valueGetter: (params) => new Date(params.row.createdAt),
      renderCell: (cellValues) => (
        <Typography>
          {new Date(cellValues.row.createdAt).toLocaleDateString()}
        </Typography>
      ),
    },
  ];

  const getSubscriptionTypeString = (
    organization: Organization,
  ): SubscriptionType => {
    if (organization.subscription?.isFreeSubscription) {
      return 'free';
    }
    if (organization.subscription?.isFreeTrial) {
      if (organization.subscription?.isAutomaticSubscriptionPaymentActive) {
        return 'freeTrialAutomatic';
      }
      return 'freeTrialManual';
    }
    if (organization.subscription?.isPaying) {
      return 'manual';
    }
    if (organization.subscription?.isAutomaticSubscriptionPaymentActive) {
      return 'automatic';
    }
    return 'none';
  };
  const rows: GridRowType[] = filteredOrganizations.map((organization) => ({
    ...organization,
    id: organization._id,
    totalMembers: organization.members?.length,
    totalVictimes: organization.victimes?.length || 0,
    subscriptionType: getSubscriptionTypeString(organization),
    maxNumberOfUsers: (organization.subscription?.maxNumberOfUsers || 0) + 1,
  }));

  const dialogAdminDeleteAccount = async () => {
    if (deleteOrganizationDialog !== '') {
      await dispatch(
        deleteOrganization({ organizationId: deleteOrganizationDialog }),
      );
      setOpenDeleteDialog(false);
      setDeleteOrganizationDialog('');
    }
  };

  const updateSubscription = async (
    organizationId: string,
    updateSubscriptionDto: UpdateSubscriptionDto,
  ) => {
    dispatch(
      updateOrganizationSubscription({
        organizationId,
        dto: updateSubscriptionDto,
      }),
    );
  };

  const cancelSubscription = async () => {
    if (cancelSubscriptionDialogSubscriptionId) {
      dispatch(
        cancelOrganizationSubscriptionAsAdmin(
          cancelSubscriptionDialogSubscriptionId.organizationId,
        ),
      );
    }
    setCancelSubscriptionDialogSubscriptionId(undefined);
  };

  const onClickRow = (params: GridRowParams<GridRowType>) => {
    setDialogOpened(true);
    setClickedOrganizationId(params.row._id);
  };

  const displayOrganizationCard = (organization: Organization) => (
    <OrganizationCard
      organization={organization}
      updateSubscription={(dto) => updateSubscription(organization._id, dto)}
      cancelSubscription={() =>
        cancelOrganizationSubscriptionAsAdmin(organization._id)
      }
    />
  );
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SearchFormField
        placeholderKey={t('pages.Admin.organization.search')}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sx={{ marginBottom: 1, marginTop: 1, width: 400 }}
      />
      <DataGrid
        sx={{
          cursor: 'default',
          boxShadow:
            'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, \
           &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
        }}
        localeText={
          i18n.language === 'en'
            ? enUS.components.MuiDataGrid.defaultProps.localeText
            : frFR.components.MuiDataGrid.defaultProps.localeText
        }
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: () => (
            <CustomNoRowsOverlay
              text={t('pages.Admin.organization.noOrganizations')}
            />
          ),
          NoResultsOverlay: CustomNoRowsOverlay,
        }}
        loading={false}
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onRowClick={onClickRow}
        onRowSelectionModelChange={(gridOrganizations) =>
          setSelectedOrganizationsIds(
            gridOrganizations.map((gridOrganization) =>
              gridOrganization.toString(),
            ),
          )
        }
        pageSizeOptions={[10, 20, 50]}
      />
      <Flex
        sx={{
          marginTop: 3,
          marginBottom: 1,
          justifyContent: 'flex-end',
          marginX: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setDialogOpened(true);
          }}
          disabled={selectedOrganizations?.length === 0}
        >
          {t('pages.Admin.organization.showOrganizations')}
        </Button>
      </Flex>
      <Dialog
        open={Boolean(
          isDialogOpened &&
            (selectedOrganizations?.length !== 0 || clickedOrganization),
        )}
        onClose={() => {
          setDialogOpened(false);
          setSelectedOrganizationsIds([]);
          setClickedOrganizationId(null);
        }}
      >
        {clickedOrganization ? (
          displayOrganizationCard(clickedOrganization)
        ) : selectedOrganizations?.length === 1 &&
          selectedOrganizations?.[0] ? (
          displayOrganizationCard(selectedOrganizations[0])
        ) : selectedOrganizations?.length > 1 ? (
          <DialogContent>
            <Grid container spacing={2}>
              {selectedOrganizations.map((organization) => {
                return (
                  <Grid item xs={12} key={organization._id}>
                    {displayOrganizationCard(organization)}
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
        ) : null}
      </Dialog>
      <ConfirmationDialog
        title={t('pages.Admin.organization.confirmDeleteOrganizationTitle')}
        open={openDeleteDialog}
        onConfirm={dialogAdminDeleteAccount}
        onCancel={() => {
          setOpenDeleteDialog(false);
          setDeleteOrganizationDialog('');
        }}
      />
      <ConfirmationDialog
        title={t('pages.Admin.confirmCancelSubscription.title')}
        open={!!cancelSubscriptionDialogSubscriptionId}
        description={t('pages.Admin.confirmCancelSubscription.description')}
        onConfirm={cancelSubscription}
        onCancel={() => setCancelSubscriptionDialogSubscriptionId(undefined)}
      />
    </Box>
  );
};
