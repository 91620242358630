import {
  Tab,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import { getIndemnisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { fCurrency } from 'src/helpers/formatNumber';
import { Bareme, BaremeScolaireValue } from 'src/types/bareme.type';
interface Props {
  baremes: Bareme[];
}
export const BaremeScolaire: React.FC<Props> = ({ baremes }) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const onChangeTabIndex = (
    _: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setTabIndex(value);
  };

  const displayLabelRow = (itt: string, label: string) => (
    <TableRow>
      <TableCell colSpan={2} align="left" variant="head">
        {t(`bareme.type.ScolaireValues.itt.${itt}`)}
      </TableCell>
      <TableCell align="left">{label}</TableCell>
    </TableRow>
  );

  const displayLevelsRow = (
    itt: string,
    levels: BaremeScolaireValue['levels'],
  ) => (
    <>
      <TableRow>
        <TableCell colSpan={2} align="left" variant="head">
          {t(`bareme.type.ScolaireValues.itt.${itt}`)}
        </TableCell>
      </TableRow>
      {levels?.map((level, index) => (
        <TableRow key={index}>
          <TableCell />
          <TableCell align="left" variant="head">
            {t(`bareme.type.ScolaireValues.level.${level.level}`)}
          </TableCell>
          <TableCell align="left">{fCurrency(level.value)}</TableCell>
        </TableRow>
      ))}
    </>
  );

  return (
    <>
      <Tabs value={tabIndex} onChange={onChangeTabIndex} variant="scrollable">
        {baremes.map((bareme, index) => (
          <Tab
            key={index}
            label={getIndemnisationBaremeSourceLabel(bareme)}
            value={index}
            sx={{ textTransform: 'none' }}
          />
        ))}
      </Tabs>
      <Typography variant="body2" fontStyle="italic" sx={{ paddingY: 1 }}>
        {baremes?.[tabIndex]?.sourceComment}
      </Typography>
      {baremes.map((bareme, index) => (
        <NormalTable
          key={index}
          sx={{ display: tabIndex === index ? '' : 'none' }}
        >
          <TableBody>
            {(bareme?.values as BaremeScolaireValue[] | undefined)?.map(
              (value, index) => (
                <React.Fragment key={index}>
                  {value.label
                    ? displayLabelRow(value.itt, value.label)
                    : displayLevelsRow(value.itt, value.levels)}
                </React.Fragment>
              ),
            )}
          </TableBody>
        </NormalTable>
      ))}
    </>
  );
};
