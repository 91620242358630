import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { Control } from 'react-hook-form';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { DatePickerForm } from '../../../forms/DatePickerForm';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { LayoutTable, SpanNoWrap } from '../../../styled';

import { MoreHoriz } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'src/components/basic/Accordion';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { HiddenFieldForm } from 'src/components/forms/HiddenFieldForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { CalculsFormCalendrier } from 'src/constants/calculs/calculsFormCalendrier';
import { CalculsFormCalendrierDepense } from 'src/constants/calculs/calculsFormCalendrierDepense';
import { CalculsFormCapitalisation } from 'src/constants/calculs/calculsFormCapitalisation';
import { CalculsGlobal } from 'src/constants/calculs/calculsGlobal';
import { fAge, fDate } from 'src/helpers/formatTime';
import {
  getMontantRevalorise,
  getQuotientRevalorisationTooltip,
} from 'src/helpers/prejudices/revalorisation';
import { Bareme } from 'src/types/bareme.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { fCurrency, fDecimalNumber } from '../../../../helpers/formatNumber';
import {
  capitalisationDateAttributionTypes,
  depenseFrequences,
  DepenseType,
  NumeroPieceValuesRow,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceType,
} from '../../../../types/prejudice.type';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { CapitalisationDiffereeTooltipText } from '../Capitalisation/CapitalisationDiffereeTooltipText';
import { CoefficientUnavailableInBaremeTooltipText } from '../Capitalisation/CoefficientUnavailableInBaremeTooltipText';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
interface Props {
  control:
    | Control<PrejudiceFormCalendrierDepenseCapitalisation>
    | Control<PrejudiceFormCalendrierDepense>;
  tiersPayeurs: string[];
  isCalculCapitalisation?: boolean;
  dateLiquidation?: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  hideDepenseRecurrente?: boolean;
  hideFrequenceViagere?: boolean;
  hidePeriode?: boolean;
  victimesIndirectes?: VictimeIndirecte[];
  isVictimeIndirecte?: boolean;
  procedure: Procedure;
  victime: Victime;
  baremesCapitalisation?: Bareme[];
  rowIndex: number;
  fieldId: string;
  fieldType: DepenseType;
  allNumerosPieces: NumeroPieceValuesRow[];
  minDate: Date;
  maxDate: Date;
  minDateJustificatif: Date;
  maxDateJustificatif: Date;
  prejudiceType: PrejudiceType;
  onClickRemove: (index: number) => void;
  onClickDuplicate: (index: number) => void;
  shouldNotDisplayCapitalisation: boolean;
  dateDeces: Date | undefined;
}
const TableDepensesRowComponent: React.FC<Props> = ({
  control,
  tiersPayeurs,
  isCalculCapitalisation,
  dateLiquidation,
  monetaryErosions,
  hideDepenseRecurrente,
  hideFrequenceViagere,
  isVictimeIndirecte,
  victimesIndirectes,
  hidePeriode,
  procedure,
  victime,
  baremesCapitalisation,
  rowIndex,
  fieldId,
  fieldType,
  allNumerosPieces,
  minDate,
  maxDate,
  minDateJustificatif,
  maxDateJustificatif,
  prejudiceType,
  onClickRemove,
  onClickDuplicate,
  shouldNotDisplayCapitalisation,
  dateDeces,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onClickRemove(rowIndex);
    handleMenuClose();
  };

  const handleDuplicate = () => {
    onClickDuplicate(rowIndex);
    handleMenuClose();
  };

  return (
    <TableRow key={fieldId}>
      <TableCell
        variant="head"
        sx={{
          zIndex: 2,
        }}
        align="center"
      >
        <FormNumeroPieceDialog
          control={
            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
          }
          name={`rows.${rowIndex}.numerosPieces`}
          displayShortPieceText
          allOtherNumeroPieces={allNumerosPieces}
        />
      </TableCell>
      {isVictimeIndirecte && (
        <TableCell
          variant="head"
          sx={{
            zIndex: 2,
            position: 'sticky',
            left: 0,
            minWidth: '250px',
          }}
        >
          <SelectFieldForm
            control={
              control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
            }
            name={`rows.${rowIndex}.victimeIndirecte`}
            options={
              victimesIndirectes?.map((victimeIndirecte) => ({
                value: victimeIndirecte._id,
                label: t('victimeIndirecte.displayNameLong', {
                  nom: victimeIndirecte.nom,
                  prenom: victimeIndirecte.prenom,
                  lienVictime: victimeIndirecte.lienVictime,
                  dateNaissance: victimeIndirecte.dateNaissance
                    ? new Date(
                        victimeIndirecte.dateNaissance,
                      ).toLocaleDateString()
                    : '',
                }),
              })) ?? []
            }
            fullWidth
          />
        </TableCell>
      )}
      <TableCell
        variant={isVictimeIndirecte ? undefined : 'head'}
        sx={
          isVictimeIndirecte
            ? {}
            : {
                position: 'sticky',
                left: 0,
                zIndex: 2,
              }
        }
      >
        <TextFieldForm
          control={
            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
          }
          name={`rows.${rowIndex}.intitule`}
          fullWidth
          label={
            t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.intitule.label',
            ) || ''
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TableCell>
      {!hidePeriode && (
        <TableCell>
          <Stack direction="row" spacing={1} width="310px">
            <Tooltip
              title={
                Boolean(
                  isCalculCapitalisation && !shouldNotDisplayCapitalisation,
                )
                  ? t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateDebut.peutEtreDansLeFuturTooltip',
                      {
                        context:
                          fieldType === 'ponctuelle' ? 'ponctuelle' : undefined,
                      },
                    ) || ''
                  : ''
              }
              placement="top"
            >
              <div>
                <DatePickerForm
                  control={
                    control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                  }
                  name={`rows.${rowIndex}.dateDebut`}
                  label={
                    fieldType === 'ponctuelle'
                      ? t(
                          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateDebut.ponctuelleLabel',
                        )
                      : t(
                          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateDebut.label',
                        )
                  }
                  TextFieldProps={{
                    InputLabelProps: { shrink: true },
                    sx: {
                      minWidth: 155,
                      width: 155,
                    },
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
            </Tooltip>
            {fieldType === 'ponctuelle' ? null : (
              <DatePickerForm
                control={
                  control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                }
                name={`rows.${rowIndex}.dateFin`}
                label={t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateFin.label',
                )}
                TextFieldProps={{
                  InputLabelProps: { shrink: true },
                  sx: {
                    minWidth: 155,
                    width: 155,
                  },
                }}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
          </Stack>
        </TableCell>
      )}
      {!hideDepenseRecurrente && (
        <>
          <TableCell colSpan={1}>
            <>
              {fieldType === 'recurrente' && (
                <SelectFieldForm
                  control={
                    control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                  }
                  name={`rows.${rowIndex}.frequenceMontant`}
                  sx={{ width: 180 }}
                  options={depenseFrequences
                    .filter(
                      (frequence) =>
                        !(hideFrequenceViagere && frequence === 'viagere'),
                    )
                    .map((frequence) => ({
                      label: t(
                        `prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.options.${frequence}`,
                      ),
                      value: frequence,
                    }))}
                  label={
                    t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.label',
                    ) || ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </>
          </TableCell>
          <TableCell colSpan={1} sx={{ minWidth: 100 }}>
            {fieldType === 'recurrente' && (
              <ComputedPropsForm
                watchFields={[`rows.${rowIndex}.frequenceMontant`]}
                compute={([frequenceMontant]) => ({
                  props: {
                    isViagere: frequenceMontant === 'viagere',
                    isNonRenseigne: frequenceMontant === 'non_renseigne',
                  },
                })}
                control={
                  control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                }
                render={({ isViagere, isNonRenseigne }) => (
                  <>
                    {(isNonRenseigne || isViagere) && (
                      <ComputedReadFieldForm
                        control={
                          control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                        }
                        name={`rows.${rowIndex}.quantite`}
                        watchFields={[]}
                        compute={() => 1}
                      />
                    )}
                    <TextFieldForm
                      control={
                        control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                      }
                      name={`rows.${rowIndex}.renouvellementMaintenance`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          suffix: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.renouvellementMaintenance.suffix',
                          ),
                        } as IMaskNumberProps,
                      }}
                      sx={{
                        display:
                          isViagere && !isNonRenseigne ? undefined : 'none',
                      }}
                    />
                    <TextFieldForm
                      control={
                        control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                      }
                      name={`rows.${rowIndex}.quantite`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: { scale: 1 },
                          suffix: t(
                            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.quantite.suffix',
                          ),
                        } as IMaskNumberProps,
                      }}
                      sx={{
                        display:
                          isViagere || isNonRenseigne ? 'none' : undefined,
                      }}
                    />
                  </>
                )}
              />
            )}
          </TableCell>
          <TableCell colSpan={1} align="center">
            {fieldType === 'recurrente' && (
              <ComputedReadFieldForm
                control={
                  control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                }
                name={`rows.${rowIndex}.dureeDansUniteChoisie`}
                watchFields={[
                  `rows.${rowIndex}.dateDebut`,
                  `rows.${rowIndex}.dateFin`,
                  `rows.${rowIndex}.frequenceMontant`,
                ]}
                compute={([dateDebut, dateFin, frequenceMontant]) =>
                  CalculsFormCalendrierDepense.getDuree({
                    dateDebut,
                    dateFin,
                    frequenceMontant,
                    dateLiquidation,
                    dateDeces,
                  })
                }
                render={(duree, [dateDebut, _, frequenceMontant]) => (
                  <SpanNoWrap>
                    {frequenceMontant === 'non_renseigne' ||
                    !CalculsFormCapitalisation.isDepenseEchue({
                      depense: {
                        dateDebut,
                        type: fieldType,
                      },
                      isCalculCapitalisation,
                      dateLiquidation,
                    })
                      ? ''
                      : `${fDecimalNumber(duree, 3)} ${t(
                          `prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.shortOptions.${frequenceMontant}`,
                          {
                            count: duree,
                          },
                        )}`}
                  </SpanNoWrap>
                )}
              />
            )}
          </TableCell>
        </>
      )}
      <TableCell>
        <DatePickerForm
          control={
            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
          }
          name={`rows.${rowIndex}.dateJustificatif`}
          minDate={
            prejudiceType === 'FRAIS_DIVERS_DES_PROCHES'
              ? undefined
              : minDateJustificatif
          }
          maxDate={
            prejudiceType === 'FRAIS_DIVERS_DES_PROCHES'
              ? undefined
              : maxDateJustificatif
          }
          label={
            t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateJustificatif.label',
            ) || ''
          }
          TextFieldProps={{
            InputLabelProps: {
              shrink: true,
            },
          }}
        />
      </TableCell>
      <TableCell align="center">
        <ComputedPropsForm
          watchFields={[`rows.${rowIndex}.dateJustificatif`]}
          compute={([dateJustificatif]) => ({
            hidden: !dateJustificatif,
            props: {},
          })}
          control={
            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
          }
          render={() => (
            <CheckboxFormField
              control={
                control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
              }
              name={`rows.${rowIndex}.montantsDejaRevalorises`}
            />
          )}
        />
      </TableCell>
      <TableCell sx={{ minWidth: '145px' }}>
        <TextFieldForm
          control={
            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
          }
          name={`rows.${rowIndex}.montantUnitaire`}
          InputProps={{
            inputComponent: MaskNumber as any,
            inputProps: {
              suffix: '€',
              numberMask: { scale: 2, signed: true },
            } as IMaskNumberProps,
          }}
          label={
            t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantUnitaire.label',
            ) || ''
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TableCell>
      {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
        <>
          <ComputedReadFieldForm
            control={
              control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
            }
            name={`rows.${rowIndex}.montantUnitaireAnnualise`}
            watchFields={[
              `rows.${rowIndex}.montantUnitaire`,
              `rows.${rowIndex}.frequenceMontant`,
              `rows.${rowIndex}.quantite`,
              `rows.${rowIndex}.renouvellementMaintenance`,
            ]}
            compute={([
              montantUnitaire,
              frequenceMontant,
              quantite,
              renouvellementMaintenance,
            ]) =>
              CalculsFormCapitalisation.getMontantUnitaireAnnualise({
                type: fieldType,
                frequenceMontant,
                montantUnitaire,
                renouvellementMaintenance,
                quantite,
              })
            }
          />
          {/* TODO : put this into a separate reusable component to display a
          montant revalorise */}
          <ComputedPropsForm
            control={
              control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
            }
            watchFields={[
              `rows.${rowIndex}.montantUnitaireAnnualise`,
              `rows.${rowIndex}.montantsDejaRevalorises`,
              `rows.${rowIndex}.dateJustificatif`,
              'revalorisationCoefficientsType',
              `rows.${rowIndex}.dateFin`,
            ]}
            compute={([
              montantUnitaireAnnualise,
              montantsDejaRevalorises,
              dateJustificatif,
              revalorisationCoefficientsType,
              dateFin,
            ]) => {
              const isDepenseCapitalisee =
                CalculsFormCalendrierDepense.isDepenseCapitalisee({
                  type: fieldType,
                  dateFin,
                  dateLiquidation,
                });
              if (
                !montantUnitaireAnnualise ||
                !dateJustificatif ||
                montantsDejaRevalorises
              ) {
                return {
                  props: {
                    montantUnitaireAnnualise,
                    montantUnitaireAnnualiseRevalorise: 0,
                    quotientRevalorisationTooltip: '',
                    isDisplayed: isDepenseCapitalisee,
                    displayMontantRevalorise: false,
                  },
                };
              }
              return {
                props: {
                  montantUnitaireAnnualise,
                  montantUnitaireAnnualiseRevalorise:
                    getMontantRevalorise({
                      anneeOrDateMontant: dateJustificatif,
                      montant: montantUnitaireAnnualise,
                      monetaryErosions,
                      anneeOrDateLiquidation: dateLiquidation,
                      coefficientsType: revalorisationCoefficientsType,
                    }) || 0,
                  quotientRevalorisationTooltip:
                    getQuotientRevalorisationTooltip({
                      anneeOrDateMontant: dateJustificatif,
                      monetaryErosions,
                      anneeOrDateLiquidation: dateLiquidation,
                      coefficientsType: revalorisationCoefficientsType,
                    }),
                  isDisplayed: isDepenseCapitalisee,
                  displayMontantRevalorise: true,
                },
              };
            }}
            render={({
              montantUnitaireAnnualise,
              montantUnitaireAnnualiseRevalorise,
              quotientRevalorisationTooltip,
              isDisplayed,
              displayMontantRevalorise,
            }) => (
              <>
                <TableCell align="right">
                  {!isDisplayed ? (
                    <SpanNoWrap />
                  ) : (
                    <SpanNoWrap>
                      {fCurrency(montantUnitaireAnnualise || 0)}
                    </SpanNoWrap>
                  )}
                </TableCell>
                <TableCell align="right">
                  {!isDisplayed ? (
                    <SpanNoWrap />
                  ) : !displayMontantRevalorise ? (
                    <SpanNoWrap>
                      {fCurrency(montantUnitaireAnnualise || 0)}
                    </SpanNoWrap>
                  ) : (
                    <Tooltip
                      title={quotientRevalorisationTooltip || ''}
                      placement="top"
                    >
                      <SpanNoWrap>
                        {fCurrency(montantUnitaireAnnualiseRevalorise || 0)}
                      </SpanNoWrap>
                    </Tooltip>
                  )}
                </TableCell>
              </>
            )}
          />
        </>
      ) : null}
      {tiersPayeurs.map((tiersPayeur, tiersPayeurIndex) => (
        <TableCell key={tiersPayeur}>
          <HiddenFieldForm
            control={
              control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
            }
            name={`rows.${rowIndex}.priseEnChargeTiersPayeurs.${tiersPayeurIndex}.tiersPayeur`}
            value={tiersPayeur}
          />
          <TextFieldForm
            control={
              control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
            }
            name={`rows.${rowIndex}.priseEnChargeTiersPayeurs.${tiersPayeurIndex}.montant`}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                suffix: '€',
                numberMask: { scale: 2, signed: true },
              } as IMaskNumberProps,
            }}
            label={tiersPayeur}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </TableCell>
      ))}
      <ComputedPropsForm
        control={
          control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
        }
        watchFields={[`rows.${rowIndex}.dateDebut`]}
        compute={([dateDebut]) => {
          const isDepenseEchue = CalculsFormCapitalisation.isDepenseEchue({
            depense: {
              dateDebut,
              type: fieldType,
            },
            isCalculCapitalisation,
            dateLiquidation,
          });
          return {
            props: {
              hidden: !isDepenseEchue && fieldType === 'recurrente',
              isDepenseEchue,
            },
          };
        }}
        render={({ hidden, isDepenseEchue }) => (
          <>
            <TableCell align="right">
              <ComputedReadFieldForm
                control={
                  control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                }
                name={`rows.${rowIndex}.montantTotal`}
                watchFields={[
                  `rows.${rowIndex}.montantUnitaire`,
                  `rows.${rowIndex}.dureeDansUniteChoisie`,
                  `rows.${rowIndex}.quantite`,
                  `rows.${rowIndex}.renouvellementMaintenance`,
                  `rows.${rowIndex}.frequenceMontant`,
                ]}
                compute={([
                  montantUnitaire,
                  dureeDansUniteChoisie,
                  quantite,
                  renouvellementMaintenance,
                  frequenceMontant,
                ]) => {
                  const montantTotal =
                    CalculsFormCalendrierDepense.montantTotal({
                      isCalculCapitalisation: isCalculCapitalisation ?? false,
                      type: fieldType,
                      frequenceMontant,
                      renouvellementMaintenance: Number(
                        renouvellementMaintenance,
                      ),
                      montantUnitaire: Number(montantUnitaire || 0),
                      dureeDansUniteChoisie: Number(dureeDansUniteChoisie),
                      quantite: Number(quantite),
                    });
                  return montantTotal || 0;
                }}
                render={(value) =>
                  !hidden ? (
                    <TextField
                      disabled
                      label={
                        isDepenseEchue
                          ? t(
                              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantTotal.label.echu',
                            )
                          : t(
                              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantTotal.label.aEchoir',
                            )
                      }
                      value={value}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: {
                            scale: 2,
                            signed: true,
                          },
                          suffix: '€',
                        } as IMaskNumberProps,
                      }}
                    />
                  ) : (
                    <SpanNoWrap />
                  )
                }
              />
            </TableCell>
            {tiersPayeurs.map((tiersPayeur, tiersPayeurIndex) => (
              <TableCell key={tiersPayeur} align="right">
                <HiddenFieldForm
                  control={
                    control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                  }
                  name={`rows.${rowIndex}.priseEnChargeTiersPayeursProratises.${tiersPayeurIndex}.tiersPayeur`}
                  value={tiersPayeur}
                />
                <ComputedReadFieldForm
                  control={
                    control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                  }
                  name={`rows.${rowIndex}.priseEnChargeTiersPayeursProratises.${tiersPayeurIndex}.montant`}
                  watchFields={[
                    `rows.${rowIndex}.priseEnChargeTiersPayeurs`,
                    `rows.${rowIndex}.dureeDansUniteChoisie`,
                    `rows.${rowIndex}.quantite`,
                    `rows.${rowIndex}.renouvellementMaintenance`,
                    `rows.${rowIndex}.frequenceMontant`,
                  ]}
                  compute={([
                    priseEnChargeTiersPayeurs,
                    dureeDansUniteChoisie,
                    quantite,
                    renouvellementMaintenance,
                    frequenceMontant,
                  ]) => {
                    const montantTiersPayeur = priseEnChargeTiersPayeurs?.find(
                      (priseEnChargeTiersPayeur) =>
                        priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
                    )?.montant;
                    if (!montantTiersPayeur) {
                      return 0;
                    }
                    if (fieldType === 'ponctuelle') {
                      return montantTiersPayeur || 0;
                    }
                    return CalculsFormCalendrier.getMontantTiersPayeurs(
                      montantTiersPayeur,
                      dureeDansUniteChoisie,
                      quantite,
                      renouvellementMaintenance || 1,
                      frequenceMontant,
                    );
                  }}
                  render={(value) =>
                    !hidden ? (
                      <TextField
                        disabled
                        label={
                          isDepenseEchue
                            ? t(
                                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.priseEnChargeTiersPayeursProratises.label.echu',
                                {
                                  tiersPayeur,
                                },
                              )
                            : t(
                                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.priseEnChargeTiersPayeursProratises.label.aEchoir',
                                {
                                  tiersPayeur,
                                },
                              )
                        }
                        value={value}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: {
                              scale: 2,
                              signed: true,
                            },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                      />
                    ) : (
                      <SpanNoWrap />
                    )
                  }
                />
              </TableCell>
            ))}
            <TableCell align="right">
              {hidden ? null : (
                <ComputedTextFieldForm
                  control={
                    control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                  }
                  name={`rows.${rowIndex}.resteACharge`}
                  watchFields={[
                    `rows.${rowIndex}.montantUnitaire`,
                    `rows.${rowIndex}.montantTotal`,
                    `rows.${rowIndex}.quantite`,
                    `rows.${rowIndex}.dureeDansUniteChoisie`,
                    `rows.${rowIndex}.frequenceMontant`,
                    `rows.${rowIndex}.renouvellementMaintenance`,
                    `rows.${rowIndex}.montantsDejaRevalorises`,
                    `rows.${rowIndex}.dateJustificatif`,
                    'revalorisationCoefficientsType',
                    `rows.${rowIndex}.priseEnChargeTiersPayeurs`,
                  ]}
                  label={
                    isDepenseEchue
                      ? t(
                          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.label.echu',
                        )
                      : t(
                          'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.label.aEchoir',
                        )
                  }
                  compute={([
                    montantUnitaire,
                    montantTotal,
                    quantite,
                    dureeDansUniteChoisie,
                    frequenceMontant,
                    renouvellementMaintenance,
                    _,
                    __,
                    ___,
                    priseEnChargeTiersPayeurs,
                  ]) => {
                    const montantsPriseEnChargeTiersPayeurs =
                      priseEnChargeTiersPayeurs.map(
                        (priseEnChargeTiersPayeur) =>
                          Number(priseEnChargeTiersPayeur.montant) || 0,
                      );
                    montantUnitaire = Number(montantUnitaire);
                    quantite = Number(quantite);
                    dureeDansUniteChoisie = Number(dureeDansUniteChoisie);
                    const resteACharge =
                      CalculsFormCalendrier.resteAChargeProratise({
                        type: fieldType,
                        frequenceMontant,
                        montantTotal,
                        montantUnitaire,
                        quantite,
                        dureeDansUniteChoisie,
                        priseEnChargeTiersPayeurs:
                          montantsPriseEnChargeTiersPayeurs,
                        renouvellementMaintenance:
                          renouvellementMaintenance || 1,
                      });
                    return resteACharge;
                  }}
                  InputProps={{
                    inputComponent: MaskNumber as any,
                    inputProps: {
                      numberMask: {
                        scale: 2,
                        signed: true,
                      },
                      suffix: '€',
                    } as IMaskNumberProps,
                  }}
                  editedFieldsName="editedFields"
                />
              )}
            </TableCell>
            <TableCell align="right">
              {hidden ? null : (
                // TODO : put this into a separate reusable component to display a montant revalorise
                <ComputedPropsForm
                  control={
                    control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                  }
                  watchFields={[
                    `rows.${rowIndex}.resteACharge`,
                    `rows.${rowIndex}.dateJustificatif`,
                    `rows.${rowIndex}.montantsDejaRevalorises`,
                    'revalorisationCoefficientsType',
                  ]}
                  compute={([
                    resteACharge,
                    dateJustificatif,
                    montantsDejaRevalorises,
                    revalorisationCoefficientsType,
                  ]) => {
                    if (
                      !resteACharge ||
                      !dateJustificatif ||
                      montantsDejaRevalorises
                    ) {
                      return {
                        props: {
                          resteACharge,
                          resteAChargeRevalorise: 0,
                          quotientRevalorisationTooltip: '',
                          displayMontantRevalorise: false,
                        },
                      };
                    }
                    return {
                      props: {
                        resteACharge,
                        resteAChargeRevalorise:
                          dateJustificatif && !montantsDejaRevalorises
                            ? getMontantRevalorise({
                                anneeOrDateMontant: dateJustificatif,
                                montant: resteACharge,
                                monetaryErosions,
                                anneeOrDateLiquidation: dateLiquidation,
                                coefficientsType:
                                  revalorisationCoefficientsType,
                              })
                            : null,
                        quotientRevalorisationTooltip:
                          dateJustificatif && !montantsDejaRevalorises
                            ? getQuotientRevalorisationTooltip({
                                anneeOrDateMontant: dateJustificatif,
                                monetaryErosions,
                                anneeOrDateLiquidation: dateLiquidation,
                                coefficientsType:
                                  revalorisationCoefficientsType,
                              })
                            : '',
                        displayMontantRevalorise: true,
                      },
                    };
                  }}
                  render={({
                    resteACharge,
                    resteAChargeRevalorise,
                    quotientRevalorisationTooltip,
                    displayMontantRevalorise,
                  }) =>
                    !displayMontantRevalorise ? (
                      <TextField
                        disabled
                        label={
                          isDepenseEchue
                            ? t(
                                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.label.echu',
                              )
                            : t(
                                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.label.aEchoir',
                              )
                        }
                        value={resteACharge}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: {
                              scale: 2,
                              signed: true,
                            },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                      />
                    ) : (
                      <Tooltip
                        title={quotientRevalorisationTooltip || ''}
                        placement="top"
                      >
                        <TextField
                          disabled
                          label={
                            isDepenseEchue
                              ? t(
                                  'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.label.echu',
                                )
                              : t(
                                  'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.label.aEchoir',
                                )
                          }
                          value={resteAChargeRevalorise || 0}
                          InputProps={{
                            inputComponent: MaskNumber as any,
                            inputProps: {
                              numberMask: {
                                scale: 2,
                                signed: true,
                              },
                              suffix: '€',
                            } as IMaskNumberProps,
                          }}
                        />
                      </Tooltip>
                    )
                  }
                />
              )}
            </TableCell>
          </>
        )}
      />
      {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
        <ComputedPropsForm
          control={
            control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
          }
          watchFields={[`rows.${rowIndex}.dateFin`]}
          compute={([dateFin]) => ({
            props: {
              hidden: !CalculsFormCalendrierDepense.isDepenseCapitalisee({
                dateFin,
                dateLiquidation,
                type: fieldType,
              }),
            },
          })}
          render={({ hidden }) => (
            <>
              {hidden ? null : (
                <>
                  <TableCell align="center" sx={{ minWidth: 200 }}>
                    <SelectFieldForm
                      control={
                        control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                      }
                      name={`rows.${rowIndex}.capitalisation.dateAttributionType`}
                      options={capitalisationDateAttributionTypes.map(
                        (dateAttributionType, index) => ({
                          label: t(
                            `prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.dateAttribution.${dateAttributionType}`,
                          ),
                          value: capitalisationDateAttributionTypes[index],
                        }),
                      )}
                      fullWidth
                      sx={{ minWidth: 340 }}
                      placeholder={t(
                        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.dateAttribution.columnHeader',
                      )}
                      allowNoSelection
                    />
                  </TableCell>
                  <TableCell align="center">
                    <ComputedReadFieldForm
                      control={
                        control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                      }
                      watchFields={[
                        `rows.${rowIndex}.capitalisation.dateAttributionType`,
                        `rows.${rowIndex}.dateDebut`,
                        `rows.${rowIndex}.frequenceMontant`,
                        `rows.${rowIndex}.quantite`,
                        `rows.${rowIndex}.renouvellementMaintenance`,
                      ]}
                      compute={([
                        dateAttributionType,
                        dateDebut,
                        frequenceMontant,
                        quantite,
                        renouvellementMaintenance,
                      ]) => {
                        let date;
                        if (dateAttributionType === 'liquidation') {
                          date = dateLiquidation;
                        } else {
                          date =
                            CalculsFormCalendrierDepense.getDateAttribution({
                              dateDebut,
                              frequenceMontant,
                              dateLiquidation: procedure.dateLiquidation,
                              quantite,
                              renouvellementMaintenance,
                            });
                        }
                        return date?.toISOString() || null;
                      }}
                      name={`rows.${rowIndex}.capitalisation.dateAttribution`}
                      render={(value) => {
                        const age =
                          victime.dateNaissance && value
                            ? CalculsGlobal.getAge(
                                victime.dateNaissance,
                                new Date(value),
                              )
                            : null;
                        return (
                          <SpanNoWrap>
                            {age || age === 0 ? (
                              <>
                                {fAge(age)}
                                <br />
                                {`(${fDate(value)})`}
                              </>
                            ) : (
                              ''
                            )}
                          </SpanNoWrap>
                        );
                      }}
                    />
                  </TableCell>
                </>
              )}
              <TableCell colSpan={2}>
                {hidden ? null : (
                  <ComputedPropsForm
                    control={
                      control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                    }
                    watchFields={[`rows.${rowIndex}.dateFin`]}
                    compute={([dateFin]) => {
                      const { age, isViager } =
                        CalculsFormCalendrierDepense.getAgeDernierArrerage({
                          dateFin,
                          dateNaissance: victime.dateNaissance,
                        });
                      return {
                        props: {
                          ageDernierArrerage: age,
                          isLastArrerageViager: isViager,
                        },
                      };
                    }}
                    valuesToSetAfterCompute={
                      {
                        [`rows.${rowIndex}.capitalisation.ageDernierArrerage`]:
                          'ageDernierArrerage',
                        [`rows.${rowIndex}.capitalisation.isLastArrerageViager`]:
                          'isLastArrerageViager',
                      } as const
                    }
                    render={({ ageDernierArrerage, isLastArrerageViager }) => (
                      <SpanNoWrap>
                        {isLastArrerageViager
                          ? t(
                              'prejudice.capitalisation.form.fields.isLastArrerageViager.label',
                            )
                          : ageDernierArrerage !== null
                            ? fAge(ageDernierArrerage)
                            : ''}
                      </SpanNoWrap>
                    )}
                  />
                )}
              </TableCell>
              <TableCell align="right">
                {hidden ? null : (
                  <ComputedTextFieldForm
                    control={
                      control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                    }
                    name={`rows.${rowIndex}.capitalisation.coefficient`}
                    watchFields={[
                      `rows.${rowIndex}.capitalisation.dateAttribution`,
                      `rows.${rowIndex}.capitalisation.ageDernierArrerage`,
                      `rows.${rowIndex}.capitalisation.isLastArrerageViager`,
                      'capitalisation.bareme',
                      'capitalisation.enableCapitalisationDifferee',
                    ]}
                    compute={([
                      dateAttribution,
                      ageDernierArrerage,
                      isLastArrerageViager,
                      baremeCapitalisation,
                      enableCapitalisationDifferee,
                    ]) =>
                      CalculsFormCalendrierDepense.getCapitalisationCoefficient(
                        {
                          dateAttribution,
                          ageDernierArrerage,
                          isLastArrerageViager,
                          baremeCapitalisation,
                          baremesCapitalisation: baremesCapitalisation ?? [],
                          victime,
                          dateLiquidation,
                          enableCapitalisationDifferee,
                        },
                      )
                    }
                    computeTooltip={(
                      [
                        dateAttribution,
                        _,
                        __,
                        baremeCapitalisation,
                        enableCapitalisationDifferee,
                      ],
                      fieldValue,
                    ) => {
                      const bareme = baremesCapitalisation?.find(
                        (bareme) => bareme._id === baremeCapitalisation,
                      );
                      const ageDateAttribution =
                        victime.dateNaissance && dateAttribution
                          ? CalculsGlobal.getAge(
                              victime.dateNaissance,
                              new Date(dateAttribution),
                            )
                          : null;
                      const ageLiquidation =
                        victime.dateNaissance && dateLiquidation
                          ? CalculsGlobal.getAge(
                              victime.dateNaissance,
                              new Date(dateLiquidation),
                            )
                          : null;
                      const difference =
                        ageLiquidation !== null && ageDateAttribution !== null
                          ? ageDateAttribution - ageLiquidation
                          : null;
                      const isCapitalisationDifferee =
                        bareme?.source !== 'CJ' &&
                        enableCapitalisationDifferee &&
                        difference !== null &&
                        difference > 0;
                      if (
                        !!bareme &&
                        bareme.source !== 'CJ' &&
                        !!victime.dateNaissance &&
                        !!dateAttribution &&
                        fieldValue === null
                      ) {
                        return (
                          <CoefficientUnavailableInBaremeTooltipText
                            isCapitalisationDifferee={isCapitalisationDifferee}
                          />
                        );
                      }
                      if (isCapitalisationDifferee) {
                        return <CapitalisationDiffereeTooltipText />;
                      }
                      return '';
                    }}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 3 },
                        nullable: true,
                      } as IMaskNumberProps,
                    }}
                    fullWidth
                    editedFieldsName="editedFields"
                  />
                )}
              </TableCell>
              <TableCell>
                {hidden ? null : (
                  <ComputedTextFieldForm
                    name={`rows.${rowIndex}.capitalisation.montantCapitalise`}
                    control={
                      control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                    }
                    watchFields={[
                      `rows.${rowIndex}.montantUnitaireAnnualise`,
                      `rows.${rowIndex}.capitalisation.coefficient`,
                      `rows.${rowIndex}.montantsDejaRevalorises`,
                      `rows.${rowIndex}.dateJustificatif`,
                      'revalorisationCoefficientsType',
                    ]}
                    compute={([
                      montantUnitaireAnnualise,
                      coefficient,
                      montantsDejaRevalorises,
                      dateJustificatif,
                      revalorisationCoefficientsType,
                    ]) =>
                      CalculsFormCalendrierDepense.getMontantCapitalise({
                        montantUnitaireAnnualise,
                        coefficient,
                        montantsDejaRevalorises,
                        dateJustificatif,
                        dateLiquidation,
                        monetaryErosions,
                        revalorisationCoefficientsType,
                      })
                    }
                    fullWidth
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: {
                          scale: 2,
                          signed: true,
                        },
                        suffix: '€',
                      } as IMaskNumberProps,
                      sx: { fontWeight: 'bold' },
                    }}
                    editedFieldsName="editedFields"
                  />
                )}
              </TableCell>
              {tiersPayeurs.map((tiersPayeur, tiersPayeurIndex) => (
                <TableCell align="right" key={tiersPayeurIndex}>
                  {hidden ? null : (
                    <Accordion
                      title={tiersPayeur}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      <LayoutTable>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ minWidth: 150 }}>
                              <TextFieldForm
                                control={
                                  control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                                }
                                label={t(
                                  'prejudice.capitalisation.form.fields.sommeACapitaliser.title',
                                )}
                                name={`rows.${rowIndex}.capitalisationTiersPayeurs.parTiersPayeur.${tiersPayeurIndex}.sommeACapitaliser`}
                                InputProps={{
                                  inputComponent: MaskNumber as any,
                                  inputProps: {
                                    numberMask: { scale: 2 },
                                    suffix: '€',
                                  } as IMaskNumberProps,
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ minWidth: 150 }}>
                              <TextFieldForm
                                control={
                                  control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                                }
                                label={t(
                                  'prejudice.capitalisation.form.fields.coefficient.titleWithoutParenthesis',
                                )}
                                name={`rows.${rowIndex}.capitalisationTiersPayeurs.parTiersPayeur.${tiersPayeurIndex}.coefficient`}
                                InputProps={{
                                  inputComponent: MaskNumber as any,
                                  inputProps: {
                                    numberMask: { scale: 3 },
                                  } as IMaskNumberProps,
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ minWidth: 180 }}>
                              <ComputedTextFieldForm
                                control={
                                  control as Control<PrejudiceFormCalendrierDepenseCapitalisation>
                                }
                                label={t(
                                  'prejudice.capitalisation.form.fields.montantCapitalise.title',
                                )}
                                name={`rows.${rowIndex}.capitalisationTiersPayeurs.parTiersPayeur.${tiersPayeurIndex}.montantCapitalise`}
                                watchFields={[
                                  `rows.${rowIndex}.capitalisationTiersPayeurs.parTiersPayeur.${tiersPayeurIndex}.sommeACapitaliser`,
                                  `rows.${rowIndex}.capitalisationTiersPayeurs.parTiersPayeur.${tiersPayeurIndex}.coefficient`,
                                ]}
                                compute={([sommeACapitaliser, coefficient]) =>
                                  sommeACapitaliser * (coefficient || 0)
                                }
                                InputProps={{
                                  inputComponent: MaskNumber as any,
                                  inputProps: {
                                    numberMask: { scale: 2 },
                                    suffix: '€',
                                  } as IMaskNumberProps,
                                }}
                                editedFieldsName="editedFields"
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </LayoutTable>
                    </Accordion>
                  )}
                </TableCell>
              ))}
            </>
          )}
        />
      ) : null}
      <TableCell align="center">
        <IconButton onClick={handleMenuOpen}>
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDelete}>
            {t('forms.table.deleteRow', { index: rowIndex + 1 })}
          </MenuItem>
          <MenuItem onClick={handleDuplicate}>
            {t('forms.table.duplicateRow', { index: rowIndex + 1 })}
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

// export const TableDepensesRow = TableDepensesRowComponent;

export const TableDepensesRow = memo(TableDepensesRowComponent);
