import React, { useEffect } from 'react';

import { Container, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  AuthenticationContentStyle,
  AuthenticationStyle,
} from 'src/components/authentication/AuthenticationStyles';
import SignUpForm from 'src/containers/authentication/SignUpForm';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { resetError } from 'src/slices/auth';
import { AuthSocial } from '../components/authentication/';
import { APP_NAME } from '../constants/global';

export const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { search } = useLocation();

  const invitationToken = React.useMemo(
    () => new URLSearchParams(search).get('invitation'),
    [search],
  );
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.auth.error);
  useEffect(() => {
    if (error === 'accountDisabled') {
      navigate('/accountNotActivated');
      dispatch(resetError());
    }
  }, [error]);
  return (
    <AuthenticationStyle title={`Register | ${APP_NAME}`}>
      <Container sx={{ maxWidth: '400px' }}>
        <AuthenticationContentStyle>
          <Stack sx={{ mb: 5, mt: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('pages.SignUp.signUp')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('pages.SignUp.createAccount')}
            </Typography>
          </Stack>
          <AuthSocial invitationToken={invitationToken ?? undefined} />

          <SignUpForm invitationToken={invitationToken ?? undefined} />

          <Typography
            variant="body2"
            align="center"
            sx={{ color: 'text.secondary', mt: 3 }}
          >
            <Link underline="always" to="/" component={RouterLink}>
              {t('pages.SignUp.backToSignIn')}
            </Link>
          </Typography>
        </AuthenticationContentStyle>
      </Container>
    </AuthenticationStyle>
  );
};
