import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminAppConstants } from 'src/components/admin/AdminAppConstants';
import { AdminInvitations } from 'src/components/admin/AdminInvitations';
import { AdminOrganizations } from 'src/components/admin/AdminOrganizations';
import { AdminUsers } from 'src/components/admin/AdminUsers';
import { theme } from 'src/constants/theme';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { useOrganizationsForNormaAdmin } from 'src/hooks/store/organization.hooks';
import { adminGetAllUsers } from 'src/slices/auth';
import { fetchConstant } from 'src/slices/constant';
import { fetchAllInvitations } from 'src/slices/invitation';
import { fetchOrganizationsForNormaAdmin } from 'src/slices/organization';
import { appConstantsSelector, invitationSelectors } from 'src/store/selectors';
import { Organization } from 'src/types/organization.type';

const TabContent: React.FC<{
  selectedIndex: number;
  index: number;
  children: React.ReactNode;
}> = ({ selectedIndex, index, children }) => (
  <Box
    sx={{
      display: selectedIndex === index ? undefined : 'none',
      height: '100%',
    }}
  >
    {children}
  </Box>
);
const Admin = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { users } = useAppSelector(({ auth: { users } }) => ({
    users,
  }));
  const invitations = useAppSelector((state) =>
    invitationSelectors.selectAll(state),
  );
  const appConstants = useAppSelector(appConstantsSelector.selectAll);
  const organizations = useOrganizationsForNormaAdmin();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);

  useEffect(() => {
    dispatch(adminGetAllUsers());
    dispatch(fetchAllInvitations());
    dispatch(fetchConstant());
    dispatch(fetchOrganizationsForNormaAdmin());
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        [theme.breakpoints.down('md')]: {
          height: '95%',
        },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
        <Tab label={t('pages.Admin.tabs.users')} />
        <Tab label={t('pages.Admin.tabs.organizations')} />
        <Tab label={t('pages.Admin.tabs.invitations')} />
        <Tab label={t('pages.Admin.tabs.appConstants')} />
      </Tabs>
      <TabContent selectedIndex={tabIndex} index={0}>
        <AdminUsers
          users={users}
          setTabIndex={setTabIndex}
          setSelectedOrganization={setSelectedOrganization}
        />
      </TabContent>
      <TabContent selectedIndex={tabIndex} index={1}>
        <AdminOrganizations
          organizations={organizations}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
        />
      </TabContent>
      <TabContent selectedIndex={tabIndex} index={2}>
        <AdminInvitations invitations={invitations} />
      </TabContent>
      <TabContent selectedIndex={tabIndex} index={3}>
        <AdminAppConstants appConstants={appConstants?.[0]} />
      </TabContent>
    </Box>
  );
};

export default Admin;
