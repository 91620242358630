import {
  Tab,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import { getIndemnisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { sortBaremesIndemnisation } from 'src/helpers/bareme/sortAndOrder';
import { fCurrency } from 'src/helpers/formatNumber';
import { Bareme, BaremeIndemnisationValues } from 'src/types/bareme.type';
interface Props {
  baremes: Bareme[];
}
export const BaremeIndemnisation: React.FC<Props> = ({ baremes }) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const sortedBaremes = useMemo(
    () => sortBaremesIndemnisation(baremes),
    [baremes],
  );
  const onChangeTabIndex = (
    _: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setTabIndex(value);
  };

  const getValue = (value: BaremeIndemnisationValues) =>
    value.fromValue && value.toValue
      ? t('bareme.type.IndemnisationValues.value.fromValueToValue', {
          fromValue: fCurrency(value.fromValue),
          toValue: fCurrency(value.toValue),
        })
      : value.fromValue
        ? t('bareme.type.IndemnisationValues.value.fromValue', {
            fromValue: fCurrency(value.fromValue),
          })
        : value.toValue
          ? t('bareme.type.IndemnisationValues.value.toValue', {
              toValue: fCurrency(value.toValue),
            })
          : value.value
            ? fCurrency(value.value)
            : '';

  const isPrixDuPointColumnVisible = useMemo(
    () =>
      (
        sortedBaremes?.[tabIndex]?.values as
          | BaremeIndemnisationValues[]
          | undefined
      )?.some((value) => value.pointPrice),
    [sortedBaremes, tabIndex],
  );

  const isValueColumnVisible = useMemo(
    () =>
      (
        sortedBaremes?.[tabIndex]?.values as
          | BaremeIndemnisationValues[]
          | undefined
      )?.some((value) => value.fromValue || value.toValue || value.value),
    [sortedBaremes, tabIndex],
  );

  const isDegreeLabelColumnVisible = useMemo(
    () =>
      (
        sortedBaremes?.[tabIndex]?.values as
          | BaremeIndemnisationValues[]
          | undefined
      )?.some((value) => value.degreeLabel),
    [sortedBaremes, tabIndex],
  );

  const isAverageColumnVisible = useMemo(
    () =>
      (
        sortedBaremes?.[tabIndex]?.values as
          | BaremeIndemnisationValues[]
          | undefined
      )?.some((value) => value.average),
    [sortedBaremes, tabIndex],
  );
  return (
    <>
      <Tabs value={tabIndex} onChange={onChangeTabIndex} variant="scrollable">
        {sortedBaremes.map((bareme, index) => (
          <Tab
            key={index}
            label={getIndemnisationBaremeSourceLabel(bareme)}
            value={index}
            sx={{ maxWidth: 'none', textTransform: 'none' }}
          />
        ))}
      </Tabs>
      <Typography variant="body2" fontStyle="italic" sx={{ paddingY: 1 }}>
        {sortedBaremes?.[tabIndex]?.sourceComment}
      </Typography>
      {sortedBaremes.map((bareme, index) => (
        <NormalTable
          key={index}
          sx={{ display: tabIndex === index ? '' : 'none' }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={isDegreeLabelColumnVisible ? 2 : 1}
                align="left"
              >
                {t('bareme.type.IndemnisationValues.degree.label')}
              </TableCell>
              {isPrixDuPointColumnVisible && (
                <TableCell align="left">
                  {t('bareme.type.IndemnisationValues.pointPrice.label')}
                </TableCell>
              )}
              {isValueColumnVisible && (
                <TableCell align="left">
                  {t(
                    `bareme.type.IndemnisationValues.value${
                      bareme.additionalProperties?.prejudice
                        ? `.labelsByPrejudice.${bareme.additionalProperties?.prejudice}`
                        : '.label'
                    }`,
                  )}
                </TableCell>
              )}
              {isAverageColumnVisible && (
                <TableCell align="left">
                  {t('bareme.type.IndemnisationValues.average.label')}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(bareme?.values as BaremeIndemnisationValues[] | undefined)?.map(
              (value, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{value.degree}</TableCell>
                  {value.degreeLabel && (
                    <TableCell align="left">{value.degreeLabel}</TableCell>
                  )}
                  {value.pointPrice && (
                    <TableCell align="left">
                      <Typography>{fCurrency(value.pointPrice)}</Typography>
                    </TableCell>
                  )}
                  {value.fromValue || value.toValue || value.value ? (
                    <TableCell align="left">{getValue(value)}</TableCell>
                  ) : null}
                  {value.average && (
                    <TableCell align="left">
                      <Typography>{fCurrency(value.average)}</Typography>
                    </TableCell>
                  )}
                </TableRow>
              ),
            )}
          </TableBody>
        </NormalTable>
      ))}
    </>
  );
};
