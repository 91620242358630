import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { FullscreenSpin } from 'src/components/basic/FullscreenSpin';
import { LoggedLayout } from 'src/containers/LoggedLayout';
import {
  isAutomaticSubscriptionAvailableForOrganization,
  isOrganizationSubscriptionValid,
  isSubscriptionQuoteAvailableForOrganization,
} from 'src/helpers/subscription';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { useAuthenticatedUser } from 'src/hooks/store/auth.hooks';
import {
  useDisplayedOrganization,
  useOrganizations,
} from 'src/hooks/store/organization.hooks';
import Admin from 'src/pages/Admin';
import Client from 'src/pages/Client';
import { CreateOrganization } from 'src/pages/CreateOrganization';
import Dashboard from 'src/pages/Dashboard';
import Help from 'src/pages/Help';
import { Invitations } from 'src/pages/Invitations';
import { OrganizationConfiguration } from 'src/pages/OrganizationConfiguration';
import { OrganizationEdition } from 'src/pages/OrganizationEdition';
import { OrganizationQuote } from 'src/pages/OrganizationQuote';
import Settings from 'src/pages/Settings';
import SubscriptionOptions from 'src/pages/SubscriptionOptions';
import SubscriptionSuccessful from 'src/pages/SubscriptionSuccessful';
import { fetchAllOrganizations } from 'src/slices/organization';
import { fetchAllInvitationsbyReceiver } from 'src/slices/organizationInvitation';
import { organizationInvitationSelector } from 'src/store/selectors';
import { Organization as IOrganization } from 'src/types/organization.type';
import { BaremePage } from '../pages/BaremePage';
import { PATH_VICTIME } from './paths';
import { SentryRoutes } from './sentry';

export type TOpenEditOrganization =
  | {
      Organization?: IOrganization;
    }
  | undefined;
export const OrganizationSwitch: React.FC = () => {
  const dispatch = useAppDispatch();
  const authenticatedUser = useAuthenticatedUser();
  const { isInitialLoading: isOrganizationsInitialLoading, organizations } =
    useOrganizations();
  const { organizationId, organization } = useDisplayedOrganization();
  const { invitations, isInitialLoading: isInvitationInitialLoading } =
    useAppSelector((state) => ({
      invitations: organizationInvitationSelector.selectAll(state),
      isLoading: state.organizationInvitation.isLoading,
      isInitialLoading: state.organizationInvitation.isInitialLoading,
      error: state.organizationInvitation.error,
    }));

  const [openEditOrganization, setOpenEditOrganization] =
    useState<TOpenEditOrganization>(undefined);

  useEffect(() => {
    dispatch(fetchAllOrganizations());
    dispatch(fetchAllInvitationsbyReceiver());
  }, [dispatch]);

  const validOrganizations = useMemo(
    () => organizations.filter(isOrganizationSubscriptionValid),
    [organizations],
  );

  const userHasNoOrganization = organizations.length === 0;
  const userHasNoInvitations = invitations.length === 0;
  const organizationSubscriptionNotValid =
    !organization ||
    !isOrganizationSubscriptionValid(organization.subscription);
  const validOrganizationSelected =
    !!organization && !organizationSubscriptionNotValid;
  const automaticSubscriptionAvailable =
    !!organization &&
    isAutomaticSubscriptionAvailableForOrganization(organization);
  const isQuoteAvailable =
    !!organization && isSubscriptionQuoteAvailableForOrganization(organization);

  const getRedirectedRoutes = () => {
    if (validOrganizationSelected) {
      return <Navigate to={`/organization/${organizationId}/`} replace />;
    }
    if (validOrganizations[0]) {
      return (
        <Navigate to={`/organization/${validOrganizations[0]._id}/`} replace />
      );
    }
    if (organizations[0]) {
      return <Navigate to={`/organization/${organizations[0]._id}/`} replace />;
    }
    if (userHasNoOrganization && !userHasNoInvitations) {
      return <Invitations isWelcomePage />;
    }
    if (userHasNoOrganization) {
      return <CreateOrganization />;
    }
    return null;
  };

  const renderOrganizationRoutes = () => (
    <SentryRoutes>
      {isQuoteAvailable && (
        <Route
          path="/quote"
          element={
            <OrganizationQuote
              isOrganizationActivation={organizationSubscriptionNotValid}
            />
          }
        />
      )}
      {automaticSubscriptionAvailable && (
        <>
          <Route path="/subscription" element={<SubscriptionOptions />} />
          <Route
            path="/subscription/successful"
            element={<SubscriptionSuccessful />}
          />
        </>
      )}
      {organizationSubscriptionNotValid ? (
        <Route
          path="*"
          element={<OrganizationQuote isOrganizationActivation />}
        />
      ) : (
        <>
          <Route path="/help" element={<Help />} />
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/settings"
            element={
              <OrganizationConfiguration
                setOpenEditOrganization={setOpenEditOrganization}
              />
            }
          />
          <Route path={`${PATH_VICTIME}/*`} element={<Client />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );

  if (
    (organizations.length === 0 || !organizations[0] || !organization) &&
    (isInvitationInitialLoading || isOrganizationsInitialLoading)
  ) {
    return (
      <LoggedLayout isOrganizationLayout noResources>
        <FullscreenSpin />
      </LoggedLayout>
    );
  }

  return (
    <LoggedLayout isOrganizationLayout noResources={!validOrganizationSelected}>
      <SentryRoutes>
        <Route
          path="/organization/:organizationId/*"
          element={
            !!organization ? renderOrganizationRoutes() : getRedirectedRoutes()
          }
        />
        <Route path="/bareme/:chosenBaremeType" element={<BaremePage />} />
        <Route path="/invitations" element={<Invitations />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/create-organization" element={<CreateOrganization />} />
        {authenticatedUser?.isAdmin && (
          <Route path="/admin" element={<Admin />} />
        )}
        <Route path="*" element={getRedirectedRoutes()} />
      </SentryRoutes>
      <OrganizationEdition
        isSidebar
        isOpen={!!openEditOrganization}
        closeSideBar={() => setOpenEditOrganization(undefined)}
        organization={openEditOrganization?.Organization}
      />
    </LoggedLayout>
  );
};
