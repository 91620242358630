import {
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import { fPercent } from 'src/helpers/formatNumber';
import {
  getCoefficientsMensuelsTableRowHeader,
  sortMonetaryErosions,
} from 'src/helpers/monetaryErosion';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';

interface Props {
  monetaryErosions: MonetaryErosion[];
}

type CoeffientsTypeAndCoefficientsEntries =
  | {
      coefficientsType: 'smic';
      coefficientsEntries: [
        number,
        {
          coefficient: number;
          JOPublicationDate: Date;
          effectiveDate: Date;
        }[],
      ][];
    }
  | {
      coefficientsType: 'annuel';
      coefficientsEntries: [number, number][];
    }
  | {
      coefficientsType: 'mensuel';
      coefficientsEntries: [number, [number, number][]][];
    }
  | null;
const CoefficientsAnnuelTable: React.FC<{
  coefficientsEntries: [number, number][];
}> = ({ coefficientsEntries }) => {
  const { t } = useTranslation();
  return (
    <NormalTable stickyHeader sx={{ maxWidth: 400 }}>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            {t(
              `monetaryErosion.resources.coefficientsTypes.annuel.columnHeaders.year`,
            )}
          </TableCell>
          <TableCell align="center">
            {t(
              `monetaryErosion.resources.coefficientsTypes.annuel.columnHeaders.coefficient`,
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {coefficientsEntries.map(([year, coefficient], index) => (
          <TableRow key={index}>
            <TableCell align="left">{year}</TableCell>
            <TableCell align="center">{fPercent(coefficient, 2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </NormalTable>
  );
};

const CoefficientsSmicTable: React.FC<{
  coefficientsEntries: [
    number,
    {
      coefficient: number;
      JOPublicationDate: Date;
      effectiveDate: Date;
    }[],
  ][];
}> = ({ coefficientsEntries }) => {
  const { t } = useTranslation();

  return (
    <NormalTable stickyHeader sx={{ maxWidth: 400 }}>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            {t(
              'monetaryErosion.resources.coefficientsTypes.smic.columnHeaders.effectiveDate',
            )}
          </TableCell>
          <TableCell align="center">
            {t(
              'monetaryErosion.resources.coefficientsTypes.smic.columnHeaders.coefficient',
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {coefficientsEntries.flatMap(([year, coefficients]) =>
          coefficients.map((coef, index) => (
            <TableRow key={`${year}-${index}`}>
              <TableCell align="left">
                {getCoefficientsMensuelsTableRowHeader(
                  new Date(coef.effectiveDate).getFullYear(),
                  new Date(coef.effectiveDate).getMonth(),
                )}
              </TableCell>
              <TableCell align="center">
                {coef.coefficient.toFixed(2)}
              </TableCell>
            </TableRow>
          )),
        )}
      </TableBody>
    </NormalTable>
  );
};

const CoefficientsMensuelTable: React.FC<{
  coefficientsEntries: [number, [number, number][]][];
}> = ({ coefficientsEntries }) => {
  const { t } = useTranslation();
  return (
    <NormalTable stickyHeader sx={{ maxWidth: 400 }}>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            {t(
              'monetaryErosion.resources.coefficientsTypes.mensuel.columnHeaders.month',
            )}
          </TableCell>
          <TableCell align="center">
            {t(
              'monetaryErosion.resources.coefficientsTypes.mensuel.columnHeaders.coefficient',
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {coefficientsEntries.map(([year, coefficientsByMonth]) =>
          coefficientsByMonth.map(([month, coefficient], index) => (
            <TableRow key={index}>
              <TableCell align="left">
                {getCoefficientsMensuelsTableRowHeader(year, month)}
              </TableCell>
              <TableCell align="center">{fPercent(coefficient, 2)}</TableCell>
            </TableRow>
          )),
        )}
      </TableBody>
    </NormalTable>
  );
};

export const MonetaryErosionsCoefficients: React.FC<Props> = ({
  monetaryErosions,
}) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const sortedMonetaryErosions = useMemo(
    () => sortMonetaryErosions(monetaryErosions),
    [monetaryErosions],
  );
  const selectedMonetaryErosion = useMemo(
    () => sortedMonetaryErosions[tabIndex],
    [sortedMonetaryErosions, tabIndex],
  );

  const sortedCoefficientsEntries: CoeffientsTypeAndCoefficientsEntries =
    useMemo(() => {
      if (!selectedMonetaryErosion) {
        return null;
      }
      switch (selectedMonetaryErosion.coefficientsType) {
        case 'annuel':
          return {
            coefficientsType: selectedMonetaryErosion.coefficientsType,
            coefficientsEntries: selectedMonetaryErosion.coefficientsByYear
              ? (Object.entries(
                  selectedMonetaryErosion.coefficientsByYear,
                ).sort(
                  ([year1], [year2]) => Number(year2) - Number(year1),
                ) as unknown as [number, number][])
              : [],
          };
        case 'smic':
          return {
            coefficientsType: selectedMonetaryErosion.coefficientsType,
            coefficientsEntries: selectedMonetaryErosion.coefficients
              ? (Object.entries(selectedMonetaryErosion.coefficients).sort(
                  ([year1], [year2]) => Number(year2) - Number(year1),
                ) as unknown as [
                  number,
                  {
                    coefficient: number;
                    JOPublicationDate: Date;
                    effectiveDate: Date;
                  }[],
                ][])
              : [],
          };
        case 'mensuel':
          return {
            coefficientsType: 'mensuel',
            coefficientsEntries: selectedMonetaryErosion.coefficientsByMonth
              ? (Object.entries(selectedMonetaryErosion.coefficientsByMonth)
                  .map(([year, coefficientsByMonth]) => [
                    year,
                    Object.entries(coefficientsByMonth).sort(
                      ([month1], [month2]) => Number(month2) - Number(month1),
                    ),
                  ])
                  .sort(
                    ([year1], [year2]) => Number(year2) - Number(year1),
                  ) as unknown as [number, [number, number][]][])
              : [],
          };
        default:
          return null;
      }
    }, [selectedMonetaryErosion]);

  const onChangeTabIndex = (
    _: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setTabIndex(value);
  };
  return (
    <>
      <Tabs value={tabIndex} onChange={onChangeTabIndex} variant="scrollable">
        {sortedMonetaryErosions.map((monetaryErosion, index) => (
          <Tab
            key={index}
            label={t(
              `monetaryErosion.resources.coefficientsTypes.${monetaryErosion.coefficientsType}.tabLabel`,
            )}
            value={index}
            sx={{ maxWidth: 'none', textTransform: 'none' }}
          />
        ))}
      </Tabs>
      {selectedMonetaryErosion ? (
        <>
          <Typography marginTop={2} marginX={4} variant="h6">
            {t(
              `monetaryErosion.resources.coefficientsTypes.${selectedMonetaryErosion.coefficientsType}.title`,
            )}
          </Typography>
          <Stack marginTop={2} marginX={4}>
            <Typography variant="body1">
              {t(
                `monetaryErosion.resources.coefficientsTypes.${selectedMonetaryErosion.coefficientsType}.description`,
                {
                  baseYear: selectedMonetaryErosion.baseYear,
                },
              )}
              {selectedMonetaryErosion.coefficientsType !== 'smic' ? (
                <>
                  <br />
                  {t('monetaryErosion.resources.bankId', {
                    bankId: selectedMonetaryErosion.bankId,
                  })}
                  <br />
                  {t('monetaryErosion.resources.lastUpdate', {
                    date: selectedMonetaryErosion.lastInseeUpdate
                      ? new Date(
                          selectedMonetaryErosion.lastInseeUpdate,
                        ).toLocaleDateString()
                      : undefined,
                  })}
                </>
              ) : (
                <>
                  <br />
                  {t('monetaryErosion.resources.effectiveDate')}
                  <br />
                  <Typography variant="body2" fontStyle="italic">
                    {t('monetaryErosion.resources.conversionNotice')}
                  </Typography>
                </>
              )}
            </Typography>
          </Stack>
          <TableContainer sx={{ flex: 10 }}>
            {sortedCoefficientsEntries?.coefficientsType === 'annuel' ? (
              <CoefficientsAnnuelTable
                coefficientsEntries={
                  sortedCoefficientsEntries.coefficientsEntries
                }
              />
            ) : sortedCoefficientsEntries?.coefficientsType === 'smic' ? (
              <CoefficientsSmicTable
                coefficientsEntries={
                  sortedCoefficientsEntries.coefficientsEntries
                }
              />
            ) : sortedCoefficientsEntries?.coefficientsType === 'mensuel' ? (
              <CoefficientsMensuelTable
                coefficientsEntries={
                  sortedCoefficientsEntries.coefficientsEntries
                }
              />
            ) : null}
          </TableContainer>
        </>
      ) : null}
      <Typography
        component="a"
        href={selectedMonetaryErosion?.inseeUrl}
        target="_blank"
        margin={2}
      >
        {selectedMonetaryErosion?.inseeUrl}
      </Typography>
    </>
  );
};
