import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AcceptConditions } from 'src/components/authentication/AcceptConditions';
import { AcceptNewsletter } from 'src/components/authentication/AcceptNewsletters';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { MaskPhoneNumber } from 'src/components/masks/MaskPhoneNumber';
import { useAppDispatch } from 'src/hooks/store';
import { signUp } from 'src/slices/auth';
import { SignUpDto, UserJobType } from 'src/types/auth.type';
import * as yup from 'yup';
interface Props {
  invitationToken?: string;
}
interface FormData extends SignUpDto {
  confirmPassword: string;
}

const SignUpForm: React.FC<Props> = ({ invitationToken }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const RegisterSchema = yup.object().shape({
    firstName: yup.string().min(2).max(50).required(),
    lastName: yup.string().min(2).max(50).required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string().length(10).required(),
    company: yup.string().required(),
    password: yup
      .string()
      .min(16)
      .matches(/^(?=.*[a-z]).+$/, t('forms.passwordError.lowercase') || '')
      .matches(/^(?=.*[A-Z]).+$/, t('forms.passwordError.uppercase') || '')
      .matches(/^(?=.*\d).+$/, t('forms.passwordError.number') || '')
      .matches(/^(?=.*[\W_]).+$/, t('forms.passwordError.special') || '')
      .required(),
    jobType: yup.string().oneOf(Object.values(UserJobType)).required(),
    confirmPassword: yup
      .string()
      .nullable()
      .oneOf(
        [yup.ref('password')],
        t('forms.errorConfirmPasswordNotMatch') || '',
      )
      .required(),
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const [acceptedNewsletters, setAcceptedNewsletters] = useState(false);
  const dispatch = useAppDispatch();
  const { control, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      company: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(RegisterSchema),
  });

  const onSubmit = async (values: FormData) => {
    const {
      email,
      password,
      firstName,
      lastName,
      company,
      phoneNumber,
      jobType,
    } = values;
    if (error) {
      setError(undefined);
    }
    const resultAction = await dispatch(
      signUp({
        dto: {
          email,
          password,
          firstName,
          lastName,
          company,
          phoneNumber,
          jobType,
          acceptedNewsletters,
        },
        invitationToken,
      }),
    );
    if (signUp.rejected.match(resultAction)) {
      setError(resultAction.error.message);
    }
    if (signUp.fulfilled.match(resultAction) && !invitationToken) {
      navigate('/create-organization');
    }
  };

  const onError = (e: any) => {
    console.log('error', e);
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextFieldForm
            control={control}
            fullWidth
            label={t('common.firstName')}
            name="firstName"
          />
          <TextFieldForm
            control={control}
            fullWidth
            label={t('common.lastName')}
            name="lastName"
          />
        </Stack>
        <TextFieldForm
          control={control}
          fullWidth
          label={t('common.email')}
          name="email"
          type="email"
        />

        <TextFieldForm
          control={control}
          fullWidth
          label={t('common.company')}
          name="company"
        />
        <SelectFieldForm
          control={control}
          name="jobType"
          label={t('user.jobType.label')}
          options={Object.values(UserJobType).map((jobType) => ({
            value: jobType,
            label: t(`user.jobType.options.${jobType}`),
          }))}
        />
        <TextFieldForm
          control={control}
          fullWidth
          label={t('common.phoneNumber')}
          name="phoneNumber"
          type="phone"
          InputProps={{
            inputComponent: MaskPhoneNumber as any,
          }}
        />
        <TextFieldForm
          control={control}
          name="password"
          fullWidth
          type={showPassword ? 'text' : 'password'}
          label={t('common.password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextFieldForm
          control={control}
          name="confirmPassword"
          fullWidth
          type={showPassword ? 'text' : 'password'}
          label={t('forms.confirmPassword')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <AcceptNewsletter
          checked={acceptedNewsletters}
          onClickCheck={() => setAcceptedNewsletters((previous) => !previous)}
        />
        <AcceptConditions
          checked={acceptedConditions}
          onClickCheck={() => setAcceptedConditions((previous) => !previous)}
        />
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!acceptedConditions}
        >
          {t('common.register')}
        </Button>
      </Stack>
    </Box>
  );
};

export default SignUpForm;
