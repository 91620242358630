import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { updateVictimeRoles } from 'src/slices/victime';
import { UpdateVictimeRolesDto, Victime } from 'src/types/victime.type';
import SearchFormField from '../forms/SearchFormField';
import { UpdateVictimeRolesTable } from './UpdateVictimeRolesTable';
interface Props {
  victime: Victime | null;
  open: boolean;
  onClose: () => void;
}
export const UpdateVictimeRolesDialog: React.FC<Props> = ({
  victime,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const organizationId = useDisplayedOrganization().organization?._id;
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = React.useState('');
  const onUpdateVictimeRoles = (roles: UpdateVictimeRolesDto['roles']) => {
    if (!victime || !organizationId) {
      return;
    }
    dispatch(
      updateVictimeRoles({
        organizationId,
        id: victime._id,
        dto: {
          roles,
        },
      }),
    );
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      {!victime ? null : (
        <>
          <DialogTitle>
            {t('pages.Dashboard.updateVictimeRolesDialog.title', {
              referenceDossier: victime.referenceDossierVictime,
            })}
          </DialogTitle>
          <DialogContent
            sx={{ width: '700px', height: '700px', overflowY: 'hidden' }}
          >
            {/* TODO : Simplify CSS */}
            <Stack sx={{ overflowY: 'hidden', height: '100%' }}>
              <SearchFormField
                placeholderKey={t(
                  'pages.Dashboard.updateVictimeRolesDialog.search',
                )}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                sx={{ width: '80%' }}
              />
              <UpdateVictimeRolesTable
                victime={victime}
                searchQuery={searchQuery}
                onUpdateVictimeRoles={onUpdateVictimeRoles}
              />
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
