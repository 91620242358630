import { OrganizationMemberRole } from './organization.type';
import { Subscription } from './subscription.type';
import { Victime, VictimeUserRole } from './victime.type';

interface Stats {
  lastConnection: Date;
  numberOfConnections: number;
}

interface Authorizations {
  role: 'admin' | 'user';
  localRoles?: Record<string, OrganizationMemberRole | VictimeUserRole>;
}

export enum UserJobType {
  avocat = 'AVOCAT',
  juristeCabinetAvocat = 'JURISTE_CABINET_AVOCAT',
  juristeFondIndemnisation = 'JURISTE_FOND_INDEMNISATION',
  juristeAssurance = 'JURISTE_ASSURANCE',
  expertAssures = 'EXPERT_ASSURES',
  magistrat = 'MAGISTRAT',
  autre = 'AUTRE',
}

export interface User {
  _id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email: string;
  company?: string;
  locale?: string;
  isAdmin: boolean;
  stats: Stats;
  victimes: Victime[];
  disabled: boolean;
  subscription?: Subscription;
  isInvited?: boolean;
  jobType?: UserJobType;
  googleSignUpToBeCompleted?: boolean;
  createdAt: Date;
  authorization: Authorizations;
}

/* DTO */

export type UpdateUserDto = Partial<
  Pick<User, 'firstName' | 'lastName' | 'company' | 'phoneNumber' | 'jobType'>
>;

export type SignUpDto = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  company: string;
  jobType: UserJobType;
  phoneNumber: string;
  acceptedNewsletters: boolean;
};
export type CompleteGoogleSignUpDto = Omit<SignUpDto, 'email' | 'password'>;
export type SendHelpRequestDto = {
  message: string;
};
